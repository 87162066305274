import { ref } from '@vue/composition-api';

const useTransition = () => {
  const trHeight = ref(null);

  const trAddHeight = (val) => {
    // Add height to existing height
    // Usage: Where new element is append or more height is added (e.g. list append)

    /* Assumes:
     - Height is assigned and is `String`
     - Incoming value is valid number in `Number` or `String`
    */
    const heightValue = Number(
      trHeight.value.substring(0, trHeight.value.length - 2)
    );
    trHeight.value = `${heightValue + Number(val)}px`;
  };

  const trSetHeight = (val) => {
    // Set height
    // Usage: Mostly for assigning initial value from mounted hook

    /* Assumes:
       - Height is not assigned and what to assign for add/remove operation
       - What to set height at something for odd usage
       - Incoming value is valid number in `Number` or `String`
      */
    if (val === null) trHeight.value = 'auto';
    else trHeight.value = `${Number(val)}px`;
  };

  const trTrimHeight = (val) => {
    // Remove height from existing height
    // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

    /* Assumes:
     - Height is assigned and is `String`
     - Incoming value is valid number in `Number` or `String`
    */
    const heightValue = Number(
      trHeight.value.substring(0, trHeight.value.length - 2)
    );
    trHeight.value = `${heightValue - Number(val)}px`;
  };

  return {
    // Reactive
    trHeight,

    // Methods
    trAddHeight,
    trSetHeight,
    trTrimHeight,
  };
};

export default useTransition;
