var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"taxiFormRef",staticClass:"repeater-form",style:({ height: _vm.trHeight })},_vm._l((_vm.post.postTaxis),function(postTaxi,index){return _c('b-row',{key:index,ref:"taxiItemForm",refInFor:true,staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex border rounded"},[_c('b-row',{staticClass:"flex-grow-1 p-2"},[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"Taxi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Taxi","label-for":"taxi"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.taxiList,"label":"licenceNum"},on:{"input":function (taxi) { return _vm.taxiSelected(index, taxi); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}}],null,true),model:{value:(postTaxi.taxi),callback:function ($$v) {_vm.$set(postTaxi, "taxi", $$v)},expression:"postTaxi.taxi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Rate","label-for":"driver-rate"}},[_c('b-form-input',{attrs:{"id":"driver-rate","type":"number"},on:{"input":function (rate) { return _vm.taxiRateChange(index, rate); }},model:{value:(postTaxi.taxiRate),callback:function ($$v) {_vm.$set(postTaxi, "taxiRate", $$v)},expression:"postTaxi.taxiRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),((_vm.post.postTaxis.length > 1))?_c('div',{staticClass:"pt-50 pr-50"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeTaxi(index)}}})],1):_vm._e()],1)])],1)}),1),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addNewTaxi}},[_vm._v(" Add Taxi ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }