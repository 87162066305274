<template>
  <div class="px-1">
    <span class="font-weight-bold">Note: </span>
    <b-form-textarea
      @input="addNotes"
      v-model="post.notes"
    />
  </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import usePostUtils from '@/modules/post/composables/usePostUtils';
import store from '@/store';

export default {
  setup() {
    const { storePost } = usePostUtils();

    const post = computed(() => store.getters['post/post']);

    const addNotes = () => {
      storePost(post.value);
    };

    return {
      // Reactive
      post,

      // Method
      addNotes
    };
  },
  components: { BFormTextarea },
};
</script>
