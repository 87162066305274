<template>
    <b-card>
        <b-card-header>
            <b-card-title>Summary</b-card-title>
        </b-card-header>

        <b-card-body class="pb-0">
            <b-col class="px-0">
                <div
                    class="d-flex justify-content-between align-items-center"
                    v-for="{ taxi, taxiRate } in post.postTaxis"
                >
                    <template v-if="taxi !== null">
                        <div class="mb-25">
                            <h6 class="mb-0">
                                {{ taxi.firstName }} {{ taxi.lastName }}
                            </h6>
                            <span class="text-muted">{{ taxi.licenceNum }}</span>
                        </div>
                        <p class="mb-25">{{ formatCurrency(taxiRate) }}</p>
                    </template>
                </div>
            </b-col>
        </b-card-body>

        <hr class="invoice-spacing" />

        <b-card-body class="py-0">
            <b-col class="px-0">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0">
                            {{ (post.account !== null) ? post.account.name : '' }} Account
                        </h6>
                    </div>
                    <p class="mb-25">{{ formatCurrency(post.accountCommission) }}</p>
                </div>
            </b-col>
        </b-card-body>

        <hr class="invoice-spacing" />

        <b-card-body class="py-0">
            <b-col>
                <b-row class="justify-content-end align-items-center my-2">
                    <h4 class="pr-25">TOTAL</h4>
                    <h4 class="pl-25">{{ formatCurrency(post.postTotalFee) }}</h4>
                </b-row>
            </b-col>
        </b-card-body>

        <b-button
            block
            variant="primary"
            @click="$emit('submitPost')"
        >
            {{ post.id ? 'Edit' : 'Add' }} Post
        </b-button>
    </b-card>
</template>

<script>
import {
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormCheckbox,
    BRow,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import store from '@/store';
import useUtils from '@/composables/useUtils';
import vSelect from 'vue-select';

export default {
    setup() {
        const { formatCurrency } = useUtils();

        const post = computed(() => store.getters['post/post']);

        return {
            // Reactive
            post,

            //Method
            formatCurrency,
        };
    },
    components: {
        BBadge,
        BButton,
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BCol,
        BFormCheckbox,
        BRow,
        ValidationObserver,
        ValidationProvider,
        vSelect,
    },
};
</script>
