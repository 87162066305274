<template>
  <section>
    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-sm-center">
      <b-col sm="4">
        <div class="d-flex align-items-center">
          <validation-provider
            #default="{ errors }"
            name="Account"
            rules="required"
            class="select"
          >
            <b-form-group
              label="Account"
              label-for="account"
            >
              <v-select
                :clearable="false"
                :options="accountList"
                @input="(account) => accountSelected(account)"
                input-id="account"
                label="name"
                v-model="post.account"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>

      <b-col sm="4">
        <div class="d-flex align-items-center">
          <validation-provider
            #default="{ errors }"
            name="Company"
            rules="required"
            class="select"
          >
            <b-form-group
              label="Company"
              label-for="company"
            >
              <v-select
                :clearable="false"
                :options="companyList"
                @input="(company) => companySelected(company)"
                input-id="company"
                label="name"
                v-model="post.company"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>

      <b-col sm="4">
        <validation-provider
          #default="{ errors }"
          name="Total"
          rules="required"
        >
          <b-form-group
            label="Total"
            label-for="post-total-fee"
          >
            <b-form-input
              @input="totalFeeChange"
              v-model="post.postTotalFee"
              id="post-total-fee"
              type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import usePostUtils from '@/modules/post/composables/usePostUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { getAccountCommission, storePost } = usePostUtils();

    const companyList = store.getters['company/companyList'];
    const accountList = store.getters['account/accountList'];
    const post = computed(() => store.getters['post/post']);

    const companySelected = (company) => {
      post.value.company = company;
      post.value.companyId = company.id;
      storePost(post.value);
    }

    const accountSelected = (account) => {
      post.value.account = account;
      post.value.accountId = account.id;
      storePost(post.value);
    }

    const totalFeeChange = (fee) => {
      post.value.postTotalFee = +fee;

      post.value.accountCommission = getAccountCommission(post.value);

      storePost(post.value);
    };

    return {
      // Reactive
      companyList,
      post,
      accountList,

      //Method
      accountSelected,
      companySelected,
      totalFeeChange,
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ValidationProvider,
    vSelect,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.select {
  width: 100%;
  max-width: 197px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
