<template>
  <section
    class="invoice-add-wrapper"
    v-if="dataLoaded"
  >
    <b-row class="invoice-add">
      <b-col lg="8">
        <validation-observer ref="postForm">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body class="invoice-padding pb-0">
                <post-date />

                <post-account-company-total />
              </b-card-body>

              <b-card-body class="invoice-padding">
                <post-taxi />
              </b-card-body>

              <b-card-body class="invoice-padding">
                <post-note />
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col lg="4">
        <post-summary-card @submitPost="createNewPost" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardBody, BCol, BForm, BFormCheckbox, BRow } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { required } from '@validations';
import { ValidationObserver } from 'vee-validate';
import router from '@/router';
import store from '@/store';
import PostAccountCompanyTotal from '@/modules/post/components/PostAccountCompanyTotal.vue';
import PostNote from '@/modules/post/components/PostNote.vue';
import PostSummaryCard from '@/modules/post/components/PostSummaryCard.vue';
import PostTaxi from '@/modules/post/components/PostTaxi.vue';
import PostDate from '@/modules/post/components/PostDate.vue';
import useAccount from '@/modules/account/composables/useAccount';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useGlobalSettings from '@/modules/setting/composables/useGlobalSettings';
import usePost from '@/modules/post/composables/usePost';
import useCompany from '@/modules/company/composables/useCompany';

export default {
  setup() {
    const { getTaxiList } = useTaxi();
    const { getCompanyList } = useCompany();
    const { getAccountList } = useAccount();
    const { getGlobalSetting } = useGlobalSettings();
    const { createPost, getPost, updatePost } = usePost();

    const postData = computed(() => store.getters['post/post']);
    const postForm = ref(null);
    const globalSettings = ref(null);
    const dataLoaded = ref(false);

    const createNewPost = () => {
      const post = store.getters['post/post'];
      postForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const result = (post.id === null)
          ? await createPost(post)
          : await updatePost(post);

        if (result === null) return;

        router.push({
          name: 'post-detail',
          params: { id: result.id },
        });
      });
    };

    const initData = async () => {
      store.commit('post/resetPost');
      globalSettings.value = await getGlobalSetting();
      await getAccountList();
      await getCompanyList();
      await getTaxiList();
      if (router.currentRoute.params.id) await getPost(router.currentRoute.params.id)
    };

    initData().then(_ => {
      dataLoaded.value = true;
    });

    return {
      // Reactive
      postData,
      postForm,
      dataLoaded,

      // Method
      createNewPost,

      // Validator
      required,
    };
  },
  components: {
    BCard,
    BCardBody,
    BCol,
    BForm,
    BFormCheckbox,
    BRow,
    PostAccountCompanyTotal,
    PostNote,
    PostSummaryCard,
    PostTaxi,
    PostDate,
    ValidationObserver,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
