<template>
  <section>
    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-sm-center">
      <b-col v-if="post.dateAddedUtc">
        <div class="d-flex align-items-center justify-content-start">
          <b-form-group
            label="Date Added"
            label-for="date-added"
          >
            <b-form-input
              disabled
              id="date-added"
              v-model="post.dateAddedUtc"
            />
          </b-form-group>
        </div>
      </b-col>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import store from '@/store';
import vSelect from 'vue-select';
import useUtils from "@/composables/useUtils";

export default {
  setup() {
    const { formatDateDdMmYyyyHi, convertUtcToLocalDate } = useUtils();

    const post = computed(() => store.getters['post/post']);

    const formatPostDate = () => {
      post.value.dateAddedUtc = (post.value.dateAddedUtc !== null)
        ? formatDateDdMmYyyyHi(convertUtcToLocalDate(post.value.dateAddedUtc))
        : null;
    };

    formatPostDate();

    return {
      // Reactive
      post
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    ValidationProvider,
    vSelect,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
